window.SHOPIFY_API_KEY = 'e042f69be53c3c734a5d8a1f72c59088';

window.HOTJAR_PRODUCTION_ID_KEY = '3252079';

window.HOTJAR_PRODUCTION_EBUILDER_ID_KEY = '3273171';

window.HOTJAR_STAGING_ID_KEY = '3282238';

window.SMARTLOOK_PROJECT_KEY = '31eb75f8cfb7d7d1b1e9342565cd5d176ad9217f';

